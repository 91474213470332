import {Injectable, Output, EventEmitter} from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
import * as CryptoJS from "crypto-js";

import {HttpClient, HttpHeaders} from "@angular/common/http";

@Injectable()
export class ToolsBoxService {

  //Configuration parameters //////////////////////////////////
  currentVersion: string = '3.1.3';
  localAddress: string = "http://localhost:8080/api";
  //////////////////////////////////////////////////////////////
  currentEnvironment: string;
  backEndUrl: string;
  backEndReleaseNote: string;
  //This variable will be set with a value sent by the back end
  @Output() fireUBackVersionUpdated: EventEmitter<String> = new EventEmitter();
  //A variable in the Header component will subscribe to 'fire'
  //The others components will be able to fire an event to the Header telling the variable
  //should be updated
  @Output() firePageName: EventEmitter<String> = new EventEmitter();
  //A variable in the App component will subscribe to 'fireContainsTabs'
  //The others components will be able to fire an event to the App telling the variable
  //should be updated
  @Output() fireContainsTabs: EventEmitter<boolean> = new EventEmitter<boolean>();
  //A variable in the App component will subscribe to 'fireActionInProgress'
  //The others components will be able to fire an event to the App telling the variable
  //should be updated
  @Output() fireActionInProgress: EventEmitter<boolean> = new EventEmitter();

  @Output() fireAvatarChange: EventEmitter<String> = new EventEmitter();

  //This list is initiated at the application Start
  eventLabels: any = [];

  //Contains the JWT for authentication
  authenticatedHeader: HttpHeaders = new HttpHeaders();
  jwtHelper = new JwtHelperService();
  userProfile: string;
  userId: number = 0;

  constructor(private http: HttpClient) {

    this.initServerConfig();

    console.log("Starting App using [" + this.currentEnvironment + "] environment.");
    //Get app information
    this.http.get(this.getServerAddress() + '/conf/app-info')
    .subscribe((data: any) => {
      for (let prop in data) {
        if (prop == "releaseNote") {
          this.backEndReleaseNote = data.releaseNote;
        } else if (prop == "version") {
          this.fireUBackVersionUpdated.emit(data.version);
        } else {
          this.eventLabels[prop] = data[prop];
        }
      }
    }, (_) => {
      console.error('ERROR WHILE GETTING APP INFO!');
    });
  }

  checkUserisLoggedIn = (): boolean => {
    try {
      let tokenJWT = sessionStorage.getItem("token");
      let tokenNotExpired: boolean =  tokenJWT !== null &&  !this.jwtHelper.isTokenExpired(tokenJWT);

      return tokenNotExpired === true &&
       this.getStoredUserId() != null &&
        this.getStoredUserLogin() != null;
    } catch (error) {
      console.log("catch", error);
      return false;
    }
  }




  initServerConfig = () => {
    let envName: string = (window as { [key: string]: any })["_env"]?.name as string || 'LOCAL';
    this.currentEnvironment = envName;
    this.currentVersion+='/'+envName;

    this.backEndUrl = (window as { [key: string]: any })["_env"]?.backEndUrl as string || this.localAddress;
  }

  getServerAddress = (): string => {
    return this.backEndUrl;
  }

  convertPassword = (pwd: String, salt: String): String => {

    let value = salt + "%" + pwd;
    let wordArray = CryptoJS.enc.Utf8.parse(value);
    return CryptoJS.MD5(wordArray).toString();
  }

  getVersion = (): String => {
    return this.currentVersion
  }

  //Will emit an event with the page name
  changeHeaderName = (pageName: String) => {
    this.firePageName.emit(pageName);
  }

  getEmittedValue = (): EventEmitter<any> => {
    return this.firePageName;
  }

  //Will emit an event telling if the child contains tabs or not
  tellChildContainsTabs = (containsTabs: boolean) => {
    this.fireContainsTabs.emit(containsTabs);
  }

  getEmittedContainsTabsValue = (): EventEmitter<any> => {
    return this.fireContainsTabs;
  }

  //Will emit an event telling an action is in progress or not
  tellActionIsInProgress = (containsTabs: boolean) => {
    this.fireActionInProgress.emit(containsTabs);
  }

  getEmittedActionInProgressValue = (): EventEmitter<any> => {
    return this.fireActionInProgress;
  }


  getStoredUserAvatar = (): String => {
    return sessionStorage.getItem("user_avatar");
  }

  //Will emit an event with the new avatar
  changeAvatar = (avatar: String) => {
    sessionStorage.setItem('user_avatar', avatar+'');
    this.fireAvatarChange.emit(avatar);
  }

  getStoredToken = (): String => {
    return sessionStorage.getItem("token");
  }

  getEmittedAvatarChange = (): EventEmitter<any> => {
    return this.fireAvatarChange;
  }

  getStoredUserLogin = (): String => {
    return sessionStorage.getItem("user_login");
  }

  getStoredUserId = (): String => {
    let userId =  sessionStorage.getItem("userId");
    if (!userId || userId === '0') {
      return null;
    } else {
      return userId;
    }
  }


  getStoredUserProfile = (): string => {
    this.userProfile=sessionStorage.getItem("userProfile");
    return this.userProfile;
  }

  isAdmin = () : boolean =>  {
    return ['ADMIN'].includes(this.getStoredUserProfile());
  }

  isEventManager = () : boolean =>  {
    return ['ADMIN', 'EVENT_MANAGER'].includes(this.getStoredUserProfile());
  }

  isManager = (): boolean => {
    return ['ADMIN', 'MANAGER'].includes(this.getStoredUserProfile());
  }

  generateRandomString = (length: number): string => {
    //0 == 48
    //z == 122

    //:	== 58
    //;	== 59
    //<	== 60
    //= == 61
    //> == 62
    //? == 63
    //[ == 91
    //\ == 92
    //] == 93
    //^ == 94
    //_ == 95
    //` == 96
    let forbiddenValue = Array.of(58,59,60,61,62,63,64,91,92,93,94,95,96);
    let salt: string;
    salt = "";
    for (let i = 0; i < length; i++) {

      let value = 0;
      do {
        value = Math.floor(Math.random() * (122 - 48 + 1)) + 48;
      } while (forbiddenValue.includes(value))

      salt += String.fromCharCode(value);
      salt.concat(String.fromCharCode(value));
    }
    return salt;
  }
}
