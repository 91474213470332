import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from "../../../../Models/user.component";
import {Balance} from "../../../../Models/balance.component";

@Component({
  selector: 'user-balance-tab',
  templateUrl: './balance-tab.component.html',
  styleUrls: ['./balance-tab.component.css']
})
export class BalanceTabComponent implements OnChanges {
  @Input() userBalance: Balance[];
  @Input() userId: number;
  @Input() user: User;

  balanceTypes: string[] = ['LHOL', 'EHOL'];
  showCustomModal: boolean = false;

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['user'] && !changes['user'].isFirstChange()) {
      this.showCustomModal = false;
      this.createForm();
    }
  }

  createForm() {
    this.form = this.fb.group({
      balanceId: [0, [Validators.required]],
      balanceUsr: ['', [Validators.required]],
      eventType: ['LHOL', [Validators.required]],
      balanceYear: ['', [Validators.required]],
      balanceDesc: ['', [Validators.required]],
      balance: [0, [Validators.required]],
      status: ['', [Validators.required]],
    });

  }

  switchBalanceStatus(balance: Balance, index: number) {
    if (balance.balanceId) {
      balance.status = balance.status === 'DELETED' ? 'NEW' : 'DELETED';
    } else {
      this.user.balances.splice(index, 1);
      this.userBalance.splice(index, 1);
    }
  }

  setBackgroundBalanceColor(balance: Balance, selectedBalanceId: number) {
    return balance.balanceId === selectedBalanceId ? '#90E0EF' : '#FFFFFF';
  }

  setFontColor(balance: Balance) {
    return balance.status === 'NEW' ? '#566D7E' : balance.status === 'DELETED' ? '#1520A6' : '#000000';
  }

  displayImg(balance: Balance) {
    return balance.status === 'DELETED' ? 'css/images/untrash.png' : 'css/images/trash.png';
  }

  mapBalanceFromForm(): Balance {
    return {
      balanceId: this.form.controls['balanceId'].value,
      balanceUsr: this.form.controls['balanceUsr'].value,
      eventType: this.form.controls['eventType'].value,
      balanceYear: this.form.controls['balanceYear'].value,
      balanceDesc: this.form.controls['balanceDesc'].value,
      balance: this.form.controls['balance'].value,
      status: 'NEW'
    } as Balance;
  }

  removeBalance(index: number) {
    this.userBalance.splice(index, 1);
  }

  openCustomModal() {
    this.showCustomModal = true;
  }

  closeCustomModal() {
    this.showCustomModal = false;
  }

  saveNewBalance() {
    const newBalance: Balance = this.mapBalanceFromForm();
    newBalance.status = 'NEW';
    this.userBalance.push(newBalance);
    this.user.balances.push(newBalance);
    this.createForm();
    this.closeCustomModal();
  }

  cancelNewBalance() {
    this.createForm();
    this.closeCustomModal();
  }

  editBalance(balance: Balance) {
    balance.editing = true;
  }

  saveBalance(balance: Balance) {
    balance.editing = false;
  }

  cancelEdit(balance: Balance) {
    balance.editing = false;
  }

  mapFormFromBalance(balance: Balance) {
    this.form.patchValue(balance);
  }

  initNewBalance() {
    this.createForm();
    this.openCustomModal();
  }
}
